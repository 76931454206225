import React, { ComponentProps, RefObject } from 'react';
import { Link } from 'react-router-dom';
import Sieve from '../components/Sieve';
import Icon from '../components/Icon';
import { faUserShield, faUsers, faCommentDots, faServer, faGlobe, faCircleNodes } from '@fortawesome/free-solid-svg-icons'

interface FeaturesState {
    sending: boolean;
    success: boolean;
    error: boolean;
}

class Features extends React.Component<ComponentProps<typeof Features>, FeaturesState> {
    readonly form: RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>();
    readonly email: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    state: FeaturesState = {
        sending: false,
        success: false,
        error: false
    };

    render() {
        return (
            <section id="Features">
                <div className="back">
                    <Link className="back" to="/">← Back</Link>
                </div>

                <article className="smaller">
                    <div className="row">
                        <div className="item">
                            <Icon fa={faUserShield} />

                            <p>
                                BOX (DIDs safe)<br />
                                → self custodial wallet<br />
                                → identities management<br />
                                → token transfers<br />
                                → token minting
                            </p>
                        </div>

                        <div className="item">
                            <Icon fa={faUsers} />

                            <p>
                                SOCIAL (back to the roots)<br />
                                → global sphere<br />
                                → challenge function<br />
                                → clear ranking system<br />
                                → reputation<br />
                                → no gatekeeping through moderation, filtering or restriction of access<br />
                                → token gated groups and content
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="item">
                            <Icon fa={faCommentDots} />

                            <p>
                                MESSENGER (secure by design)<br />
                                → metadata privacy<br />
                                → end-to-end encryption by default
                            </p>
                        </div>

                        <div className="item">
                            <Icon fa={faServer} />

                            <p>
                                SENGA NODE (DIY of our times)<br />
                                → monitor your node performance<br />
                                → adjust its settings<br />
                                → receive rewards
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="item">
                            <Icon fa={faGlobe} />

                            <p>
                                SITE (own your own site)<br />
                                → your cryptographically secured storage space<br />
                                → create, store, show and share digital assets. NFT included<br />
                                → publish to wide-ranging audiences while exercising full access control<br />
                                → customise and make it unique, public or private
                            </p>
                        </div>

                        <div className="item">
                            <Icon fa={faCircleNodes} />

                            <p>
                                CONNECTION (can't be more direct)<br />
                                → get to know other Senga Dappers<br />
                                → share links<br />
                                → send and receive files, tokens<br />
                                → get new members born to Senga
                            </p>
                        </div>
                    </div>
                </article>

                <figure>
                    <Sieve />
                </figure>
            </section>
        );
    }
}

export default Features;
