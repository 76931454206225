import React, {ElementType} from 'react';

interface ThickenerProps {
    as: ElementType;
    content?: string;
    speed?: number;
    delay?: number;
    offset?: number;
}

interface ThickenerState {
    className?: string;
    content: string;
}

class Thickener extends React.Component<ThickenerProps, ThickenerState> {
    readonly INITIAL_ANIMATION_DELAY: number = 500;

    private weight: number = 300;
    private charactersNum: number = 0;
    private progress: number = 0;

    private ref: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();
    private classObserver: MutationObserver | null = null;
    private animationInterval?: NodeJS.Timer;

    public static defaultProps = {
        as: 'strong',
        speed: 50,
        delay: 0,
        offset: window.outerWidth < window.outerHeight ? 50 : 100
    };

    public state: ThickenerState = {
        className: '',
        content: this.getTheContent()
    };

    private observeClass: MutationCallback = (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const className = (mutation.target as HTMLElement).className;

                if (className.indexOf('aos-animate') < 0) {
                    return;
                }

                this.animate();
            }
        }
    };

    private getTheContent(): string {
        if (!this.props.content) {
            return '';
        }

        if (this.props.as === 'strong') {
            this.weight = 400;
        }

        const doc = new DOMParser().parseFromString(this.props.content, 'text/html');
        const parts = Array.prototype.slice.call(doc.body.childNodes).map(child => child.outerHTML || child.textContent);

        return parts.reduce((prev: string, next: string) => {
            if (next[0] === '<' && next[next.length - 1] === '>') {
                return prev + next;
            }

            this.charactersNum += next.length;

            return prev + '<span style="font-weight: ' + this.weight + '">' + next + '</span>';
        }, '');
    }

    private animate(): void {
        setTimeout(() => {
            const isVisible = (element: HTMLElement): boolean => {
                const rect = element.getBoundingClientRect();
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

                return !(rect.bottom < 0 || rect.top - viewportHeight >= 0);
            }

            const pullCharactersFromSpan = (element: HTMLElement, span: HTMLSpanElement): void => {
                if (!span) {
                    return;
                }

                const content = span.innerText;

                if (!content) {
                    span.remove();

                    return;
                }

                if (!isVisible(span)) {
                    const text = document.createTextNode(content);

                    element.insertBefore(text, span);
                    span.remove();

                    this.progress += content.length;

                    return;
                }

                const text = document.createTextNode(content[0]);

                element.insertBefore(text, span);
                span.innerHTML = content.slice(1);

                this.progress++;
            }

            const animationInterval = setInterval(() => {
                const element = (this.ref.current as HTMLElement);

                if (!element) {
                    return;
                }

                const span = element.querySelector('span');

                if (!span) {
                    clearInterval(animationInterval);

                    return;
                }

                pullCharactersFromSpan(element, span)

                if (this.progress < this.charactersNum) {
                    return;
                }

                clearInterval(animationInterval);
            }, this.props.speed ?? Thickener.defaultProps.speed);

            this.animationInterval = animationInterval;
        }, this.INITIAL_ANIMATION_DELAY + (this.props.delay ?? 0));
    }

    render() {
        return (
            <this.props.as
                ref={ this.ref }
                className={ this.state.className }
                dangerouslySetInnerHTML={{ __html: this.state.content }}
                data-aos="thickening"
                data-aos-offset={ this.props.offset }
                data-aos-delay={ this.props.delay }
                data-aos-once="true"
            />
        );
    }

    componentDidMount() {
        this.classObserver = new MutationObserver(this.observeClass);

        this.classObserver.observe(this.ref.current as HTMLElement, {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    componentWillUnmount() {
        clearInterval(this.animationInterval);

        if (!this.classObserver) {
            return;
        }

        this.classObserver.disconnect();
        this.classObserver = null;
    }
}

export default Thickener;
